import React, { useEffect, useState, useRef, createRef } from "react";
import { useAppSelector } from "../hooks/hook";
import SearchInput from "./search-input";
import AutoCompleteResults from "./autocomplete-results";
import { useAutoCompleteQuery } from "../features/api/api";

interface AutoCompleteProps {
  onSearch: (value: string) => void;
}

const AutoComplete = ({ onSearch }: AutoCompleteProps) => {
  const { apiParams } = useAppSelector((state) => state.search);
    const [searchTerm, setSearchTerm] = useState<string>(apiParams.queryText);
    const [showAutoComplete, setshowAutoComplete] = useState<boolean>(false);
    const [focusCount, setFocusCount] = useState<number | null>(null);
    const ref = useRef<Array<any | null>>([createRef()]);
    const inputRef = useRef<HTMLInputElement>(null);
  const { data, error, isLoading } = useAutoCompleteQuery(
    { term: searchTerm },
    { skip: searchTerm === "" }
    );

    useEffect(() => {
        setSearchTerm(apiParams.queryText);
    }, [apiParams.queryText])

  function onSearchInputChange(value: string) {
    if (value) {
        setSearchTerm(value);
        setshowAutoComplete(true);
    } else {
      setSearchTerm("");
      }
  }

    function resetAutocomplete() {
        setFocusCount(null);
        setshowAutoComplete(false);
    }
    function onKeyPress(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === "Enter") {
            if (focusCount !== null) {
                setFocusCount(null);
            }
            else {
                onSearch((e.target as HTMLInputElement).value);
                resetAutocomplete();
            }
            resetAutocomplete();
        }
        else if (e.key === "Escape") {
            setSearchTerm("");
            resetAutocomplete();
        }
        else if (e.key === "ArrowDown" && data.length > 0) {

                if (!showAutoComplete) {
                    setshowAutoComplete(true);
                }
                ref.current[0].current?.focus();
                setFocusCount(0);
        }

        else {
            if (data && data.length > 0) {
                setshowAutoComplete(true);
            }
        }
        
    }
    function onAutocompleteKey(e: React.KeyboardEvent<HTMLButtonElement>) {
        if (e.key === "ArrowDown" && data.length > 0) {
            if (focusCount !== null) {
                if (!showAutoComplete) {
                    setshowAutoComplete(true);
                }
                if (focusCount < data.length - 1) {
                    ref.current[focusCount + 1].current?.focus();
                    setFocusCount(focusCount + 1);
                }
            }
            else {
                if (!showAutoComplete) {
                    setshowAutoComplete(true);
                }
                ref.current[0].current?.focus();
                setFocusCount(0);

            }
        }
        if (e.key === "ArrowUp" && data.length > 0) {
            if (focusCount !== null) {
                if (focusCount > 0) {
                    ref.current[focusCount - 1].current?.focus();
                    setFocusCount(focusCount - 1);
                }
                else {
                    // set focus to the input
                    setFocusCount(null);
                    inputRef.current?.focus();
                }
            }
        }
    }
    function handleBlur(e: React.FocusEvent<HTMLInputElement>) {
        if (e.relatedTarget === null || !e.relatedTarget?.hasAttribute("data-search-autocomplete-results")) {
            resetAutocomplete();
        }
        
    }
    function handleFocus(e: React.FocusEvent<HTMLInputElement>) {
        if (data && data.length > 0 && searchTerm !== "") {
            setshowAutoComplete(true);
        }

    }

    return (
    <>
      <SearchInput
                placeholder="Search"
                value={searchTerm}
                onChange={(value) => onSearchInputChange(value)}
                onEnter={(value) => {
                    onSearch(value);
                    resetAutocomplete();
                }}
                onKeyDown={(e) => { onKeyPress(e) }}
                ref={inputRef}
                onBlur={(e) => { handleBlur(e) }}
                onFocus={(e) => { handleFocus(e) }}
            />
            {searchTerm !== "" && data && data.length > 0 && (
                <div data-search-autocomplete-results className={`search-autocomplete-results ${showAutoComplete ? 'visible' : 'disabled'}`}>
                    <AutoCompleteResults
                        results={data}
                        onClick={(value) => { setSearchTerm(value); inputRef.current?.focus();  resetAutocomplete();  }}
                        focusCount={focusCount}
                        ref={ref}
                        onKeyDown={(e) => { onAutocompleteKey(e) }}
                    />
                </div>
            )}
          <button className="header-search-button search-btn btn btn-icon" type="button" onClick={() => { onSearch(searchTerm); resetAutocomplete(); }}>
              <span className="search-button-text">Search</span><i className="ph ph-magnifying-glass"></i>
          </button>

          
      
    </>
  );
};

export default AutoComplete;
